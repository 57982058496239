import { clone } from 'ramda';
import { DateTime } from 'luxon';

import {
  SUPPLIER_PRODUCTS_TABLE_HEADERS,
  SUPPLIER_PRODUCT_WITH_NO_DATA,
} from './components/supplierProductsPurchaseManagement/supplierProductsTableConstants';
import { SUPPLIER_WITH_NO_DATA } from './components/suppliersPurchaseManagement/suppliersTableConstants';
import { getISODateFromDateTime } from './purchaseManagementFormatters';

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const findItemById = (items, id, key) => {
  return items.find((item) => item[key].id === id);
};

const idExistsInItems = (items, id, key) => {
  return items.some((item) => item[key].id === id);
};

const addComparisonItems = (mutatedItems, objectToAppend, id, key, isProducts) => {
  mutatedItems.forEach((item, index) => {
    if (index > 0) {
      const productToFind = findItemById(item, id, key);
      if (productToFind) {
        objectToAppend[`comparison-${index}`] = productToFind;
      } else {
        objectToAppend[`comparison-${index}`] = isProducts ? SUPPLIER_PRODUCT_WITH_NO_DATA : SUPPLIER_WITH_NO_DATA;
      }
    }
  });
};

export const mutateComparisonItems = ({ objectifiedData, activeSort, searchFilter, key, mutationCallback }) => {
  const isProducts = key === 'product';

  if (isProducts && !objectifiedData.productPurchaseSummary) {
    return [];
  }

  if (!isProducts && !objectifiedData.supplierPurchaseSummary) {
    return [];
  }

  const firstSummaryData = isProducts
    ? objectifiedData.productPurchaseSummary
    : objectifiedData.supplierPurchaseSummary;

  const allDataInArray = [firstSummaryData, objectifiedData.secondSummaryQuery];
  if (objectifiedData.thirdSummaryQuery) {
    allDataInArray.push(objectifiedData.thirdSummaryQuery);
  }

  const mutatedItems = [];
  const comparisonProducts = [];
  allDataInArray.forEach((data) => {
    mutatedItems.push(mutationCallback(data, activeSort, searchFilter));
  });

  const idThatDoesNotExist = [];
  const mainComparison = mutatedItems[0];

  mutatedItems.forEach((data, index) => {
    if (index > 0) {
      const idsToAppend = data
        .filter((item) => !idExistsInItems(mainComparison, item[key].id, key))
        .map((item) => item[key].id);
      idThatDoesNotExist.push(...idsToAppend);
    }
  });

  mainComparison.forEach((item) => {
    const comparisonObjectToAppend = { 'comparison-0': item };
    addComparisonItems(mutatedItems, comparisonObjectToAppend, item[key].id, key, isProducts);
    comparisonProducts.push(comparisonObjectToAppend);
  });

  idThatDoesNotExist.forEach((id) => {
    const comparisonObjectToAppend = {
      'comparison-0': isProducts ? SUPPLIER_PRODUCT_WITH_NO_DATA : SUPPLIER_WITH_NO_DATA,
    };
    addComparisonItems(mutatedItems, comparisonObjectToAppend, id, key, isProducts);
    comparisonProducts.push(comparisonObjectToAppend);
  });

  return comparisonProducts;
};

export const getNumberIconComponentName = (index) => {
  return index === 1 ? 'OneIcon' : index === 2 ? 'TwoIcon' : 'ThreeIcon';
};

export const calculateProductsSum = (products, key = 'sumPrice') => {
  if (!products) return 0;
  const sum = products.reduce((accumulator, currentValue) => accumulator + currentValue[key], 0);
  return sum;
};

export const paginatedData = ({ mutatedProductItems, page, limit }) => {
  const startIndex = (page - 1) * limit;
  return mutatedProductItems.slice(startIndex, startIndex + limit);
};

export const getOnFilterHandler = ({
  currentColumns,
  emit,
  filteredColumnKey = SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
}) => {
  const onFilter = (filter) => {
    const clonedColumns = clone(currentColumns);
    const filteredColumnIndex = clonedColumns.findIndex((column) => column.key === filteredColumnKey);
    clonedColumns[filteredColumnIndex].filterActive = filter !== '';

    emit('on-column-update', { updatedColumns: clonedColumns });
  };

  return onFilter;
};

export const getPreviousPeriodDates = (toDate, fromDate) => {
  const fromDateObject = DateTime.fromISO(fromDate);
  const toDateObject = DateTime.fromISO(toDate);

  const differenceInMonths = Math.ceil(toDateObject.diff(fromDateObject, 'months').toObject().months);
  const fromDateToRequest = fromDateObject.minus({ months: differenceInMonths });
  const toDateToRequest = fromDateObject.minus({ months: 1 }).endOf('month');

  return {
    previousPeriodToDate: getISODateFromDateTime(toDateToRequest),
    previousPeriodFromDate: getISODateFromDateTime(fromDateToRequest),
  };
};

export const calculateElementsTotalWidth = (listOfElements, length) => {
  let sum = 0;
  for (let i = 0; i < length; i++) {
    const element = listOfElements[i];
    sum += element.getBoundingClientRect().width;
  }

  return sum;
};

export const getDateFromSupplierSchedule = (dayOfWeek, timeOfDay) => {
  const [hours, minutes] = timeOfDay.split(':');
  const date = new Date();
  const currentDate = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  date.setSeconds(0);
  const dIndex = daysOfWeek.indexOf(dayOfWeek);
  let dayDiff = dIndex - date.getDay();

  if (dayDiff < 0 || (dayDiff === 0 && date <= currentDate)) {
    // This makes sure that the date is in the future, if the day diff is negative, it means that the current date of the week is in the past, so we point it 7 days to the next day
    dayDiff += 7;
  }
  date.setDate(date.getDate() + dayDiff);

  return date;
};

export const getClosestScheduleFromOrderTermSchedules = (schedules) => {
  const sortedSchedule = [...schedules];
  sortedSchedule.sort((schedule1, schedule2) => {
    const date1 = getDateFromSupplierSchedule(schedule1.orderCutOffTime.dayOfWeek, schedule1.orderCutOffTime.timeOfDay);
    const date2 = getDateFromSupplierSchedule(schedule2.orderCutOffTime.dayOfWeek, schedule2.orderCutOffTime.timeOfDay);

    return date1 - date2;
  });

  return sortedSchedule[0];
};

export const getClosestOrderTermCutOffDate = (supplierId, allTerms) => {
  const relevantTerm = allTerms.find(({ supplierId: termSupplerId }) => supplierId === termSupplerId);
  if (!relevantTerm?.schedules?.length) return null;

  const closestSchedule = getClosestScheduleFromOrderTermSchedules(relevantTerm.schedules);

  return getDateFromSupplierSchedule(
    closestSchedule.orderCutOffTime.dayOfWeek,
    closestSchedule.orderCutOffTime.timeOfDay
  );
};

export const getClosestDeliveryArrivalDate = (supplierId, allTerms) => {
  const relevantTerm = allTerms.find(({ supplierId: termSupplerId }) => supplierId === termSupplerId);
  if (!relevantTerm?.schedules?.length) return null;

  const closestSchedule = getClosestScheduleFromOrderTermSchedules(relevantTerm.schedules);

  return getDateFromSupplierSchedule(
    closestSchedule.deliveryArrivalTime.dayOfWeek,
    closestSchedule.deliveryArrivalTime.timeOfDay
  );
};

export const getMinimumOrderAmount = (supplierId, allTerms) => {
  const relevantTerm = allTerms.find(({ supplierId: termSupplerId }) => supplierId === termSupplerId);
  if (!relevantTerm?.schedules?.length) return null;

  return relevantTerm.minimumOrderAmount;
};

export const getOrderBOM = (bom) => {
  let currentBom = bom;
  let count = 0;

  while (currentBom && count < 3) {
    if (currentBom.orderUnit) {
      return currentBom;
    }

    currentBom = bom?.bom;
    count++;
  }
};

export const bomIsOrderAndBillingUnit = (bom) => bom?.orderUnit && bom.billingUnit;

export const isWithinAWeekFromNow = (dateToCheck) => {
  const now = new Date();
  const oneWeekFromNow = new Date();
  now.setHours(0, 0, 0, 0);
  oneWeekFromNow.setHours(0, 0, 0, 0);
  oneWeekFromNow.setDate(now.getDate() + 7);

  return dateToCheck >= now && dateToCheck <= oneWeekFromNow;
};

const AMOUNT_OF_DATES_TO_ADD = 5;

export const getDeliveryArrivalAndCutOffDates = (supplierTerms) => {
  const arrivalDates = [];
  const cutOffDates = [];

  for (const { deliveryArrivalTime, orderCutOffTime } of supplierTerms.schedules) {
    const arrivalDateToAdd = getDateFromSupplierSchedule(deliveryArrivalTime.dayOfWeek, deliveryArrivalTime.timeOfDay);
    const cutOffDateToAdd = getDateFromSupplierSchedule(orderCutOffTime.dayOfWeek, orderCutOffTime.timeOfDay);
    for (let i = 0; i < AMOUNT_OF_DATES_TO_ADD; i++) {
      if (cutOffDateToAdd > arrivalDateToAdd) {
        arrivalDateToAdd.setDate(arrivalDateToAdd.getDate() + 7);
      }
      arrivalDates.push(new Date(arrivalDateToAdd));
      cutOffDates.push(new Date(cutOffDateToAdd));
      arrivalDateToAdd.setDate(arrivalDateToAdd.getDate() + 7);
      cutOffDateToAdd.setDate(cutOffDateToAdd.getDate() + 7);
    }
  }

  arrivalDates.sort((a, b) => a - b);
  cutOffDates.sort((a, b) => a - b);
  const arrivalDateOptions = [];
  const cutOffDateOptions = [];

  while (arrivalDateOptions.length !== AMOUNT_OF_DATES_TO_ADD) {
    for (let i = 0; i < arrivalDates.length; i++) {
      if (arrivalDateOptions.length === AMOUNT_OF_DATES_TO_ADD) break;
      const arrivalDate = new Date(arrivalDates[i]);
      const cutOffDate = new Date(cutOffDates[i]);
      arrivalDateOptions.push(arrivalDate);
      cutOffDateOptions.push(cutOffDate);
    }
  }

  return { arrivalDates: arrivalDateOptions, cutOffDates: cutOffDateOptions };
};
